.footer-links {
	width: 100%;
	margin: 10px;
	padding: 0;

	@media #{$tablet} {
		-webkit-flex: 1 0 180px;
		flex: 1 0 180px;
	}

	li {
		list-style: none;
		margin: 15px auto;

		@media #{$tablet} {
			max-width: 150px;
		}
		a {

			&:hover {
				text-decoration: none;
			}
			svg {
				fill: #fff;
				margin-right: 10px;
				transition: fill 0.2s ease;
				vertical-align: middle;
				position: relative;
				top: -2px;
				width: 22px;
				height: 22px;
			}

			&:hover svg {
				fill: #ffffff88;
			}

			&.twitter-icon:hover svg {
				fill: #55acee;
			}

			&.google-plus-icon:hover svg {
				fill: #db4437;
			}

			&.youtube-icon:hover svg {
				fill: #cd201f;
			}

			&.instagram-icon:hover svg {
				fill: #f167f5;
			}

			&.linkedin-icon:hover svg {
				fill: #0077b5;
			}

			&.pinterest-icon:hover svg {
				fill: #bd081c;
			}

			&.rss-icon:hover svg {
				fill: #f26522;
			}
		}
	}
}

footer {
	padding: 50px 0 50px 0;
	font-size: 1.1em;
	position: relative;
	background: #fff;
	color: #0088aa;

	.copyright {
		font-size: .8em;
		margin: 0 auto;
		
		@media #{$tablet} {
			text-align: center;
		}

	}
	
	&,
	a {
		color: #0088aa;
	}

	h2 {
		font-size: 1.4em;
		margin: 30px 0;
		color: #0088aa;
	}

	.footer-columns {
		@extend %flexbox;
		@include flex-flow(wrap);
		margin: -10px -10px 10px -10px;
	}

	a {
		text-decoration: none;

		&:hover {
			color: #0088aa88;
		}
	}

	.legal-line {
		width: 100%;
		padding: 30px 0;
		margin: 0;
		background-color: #222527;

		a {
			font-weight: 600;
		}
	}
}
